import React from 'react'
import { Link } from 'gatsby'
import BlockText from './block-text'
import Container from './container'

import * as styles from '../components/globals-v2.module.css'
import * as postStyles from './company.module.css'
import * as linkStyle from '../styles/components/links.module.css'
import { Image } from './Image'

const Company = props => {
  const mainImage = (props?.postImage || props.mainImage) || null

  const people = props?.people.length > 0 ? props.people : null
  const lead = props?.person.length > 0 ? props.person : people || null

  return (
    <Container class={postStyles.container}>
      <div className={postStyles.backButton}>
        <Link to='/companies/' className={`${linkStyle.linkArrow} ${styles.linkArrow} ${linkStyle.back} ${linkStyle.white}`}>
          Back
        </Link>
      </div>

      {props?.logo?.asset && (
        <section className={postStyles.companyLogo}>
          <Image
            imgObj={props.logo}
            props={{ alt: props.title }}
          />
        </section>
      )}

      <section className={postStyles.companyContent}>
        <div className={postStyles.companyContentLeft}>
          {mainImage && (
            <div className={postStyles.companyImage}>
              <Image
                imgObj={mainImage}
                props={{ alt: props.title }}
              />
            </div>
          )}
          <div className={postStyles.companyContentText}>
            {props._rawExcerpt && <BlockText blocks={props._rawExcerpt} />}
          </div>
        </div>
        <div className={postStyles.companyContentSidebar}>
          {props?.founders && (
            <div className={postStyles.sideBarItem}>Founders <span>{props.founders}</span></div>
          )}
          {props?.location && (
            <div className={postStyles.sideBarItem}>Location <span>{props.location}</span></div>
          )}
          {props?.link && (
            <div className={postStyles.sideBarItem}>Website <span><Link to={props.link} target='_blank'>{props.link.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '')}</Link></span></div>
          )}
          {props?.stage && props.stage[0] && (
            <div className={postStyles.sideBarItem}>Stage <span>{props.stage[0].title}</span></div>
          )}
          {lead && (
            <div className={postStyles.sideBarItem}>
              Lead{lead.length > 1 ? 's' : ''}
              {!lead._id ? lead.map((person) => (
                <span key={person._id}>
                  <a href={`/our-people/${person.slug.current}`}>{person.name}</a>
                </span>
              )) : <span><a href={`/our-people/${lead.slug.current}`}>{lead.name}</a></span>}
            </div>
          )}

        </div>
      </section>

      {/* <div className={`${styles.mainContent} ${styles.singleColumn}`}> */}
      {/*  <h2 className={`${styles.headline} ${styles.bullet}`}>{props.title}</h2> */}
      {/*  {props._rawExcerpt && <BlockText blocks={props._rawExcerpt} />} */}
      {/*  <a href={props.link} target='_blank' rel='noreferrer'>{props.link}</a> */}

      {/*  <div className={styles.viewMore}> */}
      {/*    <Link className={`${styles.button} ${styles.primary}`} to='/companies/'>See our other companies</Link> */}
      {/*  </div> */}
      {/* </div> */}

    </Container>
  )
}

export default Company
